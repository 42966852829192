import { InfoCircleOutlined } from '@ant-design/icons';
import { Card, Image, Popover, Row, Select, Space, Switch } from 'antd';
import React from 'react';

import allStyles, { colors } from '../../../../../helpers/allStyles';
import Break from '../../../../Break';
import GTooltip from '../../../../global/GTooltip';
import AppSettingsStoreSelector from '../../components/StoreSelector';
import { AppSettingsTabProps } from '../../NativeAppSettings';
import GPop from '../../../../global/GPop';
import utils from '../../../../../helpers/utils';
import HoverQuestion from '../../../../HoverQuestion';
import { hoverStyle } from '../StylingTab';
import MediaSelector from '../../../../legacyMediaLibrary/MediaSelector';

import aiqLongLogo from '../../../../../assets/imgs/aiq_logo_long.png';
import greenStandard from '../../../../../assets/imgs/green_standard.png';
import navLogo from '../../../../../assets/imgs/navlogo.png';
import { userUtils } from '../../../../../helpers';

export default function SkinsTab({ state, setState, bnd, ibnd, bbnd, nbnd, cbnd, changeTab, updateStoreSpecificAppSetting, ...props }: AppSettingsTabProps) {
	const { settings } = state,
		{ app } = settings;

	const newThemes = ['newDefault'],
		hasNewSkin = settings?.hasNewSkin;

	const updateTheme = (app: any, store?: boolean) => {
		let hasNewSkin = settings?.hasNewSkin || false,
			hadNewSkin = settings?.hasNewSkin;
		app = { ...settings.app, ...app };

		if (newThemes.includes(app?.theme)) hasNewSkin = true;
		else hasNewSkin = false;

		if (store) updateStoreSpecificAppSetting(app);
		else setState({ settings: { ...settings, hasNewSkin, app } });

		if (!hadNewSkin && hasNewSkin) {
			props.saveSettings({ hasNewSkin, app });
		}
	};

	const ConfirmWrapper = ({ children, theme }: any) =>
		theme !== app?.theme ? (
			<GPop
				type="danger-dashed"
				padded
				width={375}
				popConfirm
				config={{
					confirmType: 'danger-outlined',
					confirmText: 'Confirm & Switch',
				}}
				onConfirm={() => updateTheme({ theme }, state.storeID !== '-1')}
				content={
					!hasNewSkin && newThemes.includes(theme) ? (
						<>
							<Space
								align="start"
								style={{ fontWeight: 600 }}
							>
								<i
									className="fa-duotone fa-triangle-exclamation"
									style={{ color: colors.primary.pinkRed }}
								/>
								Warning!
							</Space>
							<div>
								This theme includes additional features, customization options, and an improved appearance.
								<Break air />
								<span style={{ fontWeight: 600 }}>However</span>, in order to take advantage of this theme, all of your stores must be transitioned. It is not
								possible to use this new theme for some stores and not others.
							</div>
						</>
					) : (
						<>
							<Space
								align="start"
								style={{ fontWeight: 600 }}
							>
								<i
									className="fa-duotone fa-triangle-exclamation"
									style={{ color: colors.primary.pinkRed }}
								/>
								Warning!
							</Space>
							<Break air />
							This is a legacy skin and does not include all features that are available within the New Default skin. It is also not possible to use this theme for
							some stores and not others.
						</>
					)
				}
			>
				{children}
			</GPop>
		) : (
			children
		);

	const ThemeCard = ({ title, src, theme }: any) => {
		const isSelected = theme === app?.theme || (theme === 'default' && !app?.theme && !hasNewSkin) || (theme === 'newDefault' && !app?.theme && hasNewSkin);
		return (
			<>
				<ConfirmWrapper theme={theme}>
					<div
						style={{
							padding: 8,
						}}
					>
						<Card
							size="small"
							title={title}
							style={{
								marginRight: 16,
								cursor: 'pointer',
								...(isSelected ? allStyles.shadows.darkShadow() : allStyles.shadows.lightShadow()),
								...(isSelected ? { borderColor: colors.primary.blueO(0.5) } : {}),
							}}
						>
							<embed
								className="ant-image-img"
								style={{ borderRadius: 15, width: 100, height: 195 }}
								src={src}
							/>
						</Card>
					</div>
				</ConfirmWrapper>
			</>
		);
	};

	const headerType = app?.config?.header?.type;

	return (
		<>
			<AppSettingsStoreSelector {...{ state, setState, bnd, ibnd, bbnd, nbnd, cbnd, changeTab, updateStoreSpecificAppSetting, ...props }} />
			{/* <Row style={{ paddingTop: 10, marginBottom: 20 }}>
            <span style={{ color: '#8c8c8c' }}><InfoCircleOutlined /> Choose from a pre-made layout theme below and customize it further with our additional styling tools!</span>
        </Row>

        <Break />
        <Break air v={hasNewSkin ? 10 : 20} />
        <b>THEME</b>
        <div style={{ display: 'flex' }}>

            <ThemeCard
                title={hasNewSkin ? <span style={{ fontSize: '.75rem' }}>New Default</span> : <>
                    <GTooltip tooltip='New & improved default skin!' placement='topLeft'>
                        <span style={{ fontSize: '.75rem' }}> <i className="fa-duotone fa-triangle-exclamation" style={{ color: colors.primary.primary }} /> New Default</span>
                    </GTooltip>
                </>}
                src="/static/img/gif/aiqDef.mp4"
                theme='newDefault'
            />
            {userUtils.isAdmin() && <>
                <ThemeCard
                    title={<>
                        <span style={{ fontSize: '.75rem' }}>Legacy Default</span>
                    </>}
                    src="/static/img/gif/aiqDef.mp4"
                    theme='default'
                />
                <ThemeCard
                    title={<>
                        <span style={{ fontSize: '.75rem' }}>Legacy Alt</span>
                    </>}
                    src="/static/img/gif/aiqAltSkin.mp4"
                    theme='aiqAlt'
                />
            </>}
        </div> */}

			{hasNewSkin && (
				<>
					{/* <Break air v={10} />
            <Break /> */}
					<Break
						air
						v={20}
					/>

					<Space>
						<b>HEADER</b>
						<GTooltip tooltip="Refresh the preview">
							<i
								className="fa-regular fa-arrow-rotate-left linked"
								style={{ fontSize: '.75rem' }}
								onClick={() => {
									setState({ showAppPreview: false });
									setTimeout(() => {
										setState({ showAppPreview: true });
									}, 10);
								}}
							/>
						</GTooltip>
					</Space>
					<Break
						air
						v={20}
					/>
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<div style={{ flex: 1 }}>
							<Space>
								<span style={{ color: '#8c8c8c' }}>Header Variant</span>
								<HoverQuestion
									style={hoverStyle}
									title="Change the wallet header"
								/>
							</Space>
							<Break air />
							<Select
								style={{ minWidth: 200 }}
								dropdownMatchSelectWidth={200}
								{...bnd('config/header/type', 'custom', state.storeID != '-1')}
								options={[
									{ label: 'No Logo', value: 'a' },
									{ label: 'Square Logo', value: 'b' },
									{ label: 'Wide Logo', value: 'd' },
									// { label: 'Wide Logo Alt', value: 'c' },
								]}
							/>
							{(headerType === 'c' || headerType === 'd') && (
								<div style={{ marginRight: 32 }}>
									<Break air />
									<span style={{ fontSize: '.75rem', color: utils.darkMode ? '' : '#191919' }}>
										<i
											className="fa-regular fa-circle-info"
											style={{}}
										/>{' '}
										Upload a rectangular image for wide logo header, example:
									</span>
									<Break
										air
										v="10"
									/>
									<GTooltip tooltip="Example image">
										<Card style={{ textAlign: 'center' }}>
											<div
												style={{
													padding: 5,
													borderRadius: 6,
													background: app?.config?.header?.backgroundColor || '#191919',
													...allStyles.shadows.lightShadowHover(),
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center',
												}}
											>
												<Image
													src={aiqLongLogo}
													style={{ maxHeight: 48 }}
												/>
											</div>
										</Card>
									</GTooltip>
								</div>
							)}
							{headerType === 'b' && (
								<div style={{ marginRight: 32 }}>
									<Break air />
									<span style={{ fontSize: '.75rem', color: utils.darkMode ? '' : '#191919' }}>
										<i
											className="fa-regular fa-circle-info"
											style={{}}
										/>{' '}
										Upload a square image for Square logo header, examples:
									</span>
									<Break
										air
										v="10"
									/>
									<GTooltip tooltip="Example image">
										<Card bodyStyle={{ textAlign: 'center', display: 'flex', justifyContent: 'space-around' }}>
											<Image
												src={greenStandard}
												style={{ maxHeight: 48, width: 48, ...allStyles.shadows.darkShadow() }}
											/>
											<Image
												src={navLogo}
												style={{ maxHeight: 48, width: 48, ...allStyles.shadows.lightShadowHover() }}
											/>
										</Card>
									</GTooltip>
								</div>
							)}
						</div>

						<div style={{ flex: 1 }}>
							<span style={{ color: '#8c8c8c' }}>Hide Store in Header</span>
							<Break air />
							<Switch
								size="small"
								style={{ minWidth: 36 }}
								{...bbnd('config/header/hideStoreInfo', false, state.storeID != '-1')}
							/>
						</div>

						{headerType && headerType !== 'a' && (
							<>
								<div style={{ flex: 1 }}>
									<span style={{ color: '#8c8c8c' }}>Show Cover & Logo</span>
									<Break air />
									<Switch
										size="small"
										style={{ minWidth: 36 }}
										{...bbnd('config/header/showCoverLogo', false, state.storeID != '-1')}
									/>
								</div>
							</>
						)}

						{headerType === 'b' && (
							<div style={{ flex: 1 }}>
								<Space>
									<span style={{ color: '#8c8c8c' }}>Logo</span>
									<HoverQuestion
										style={hoverStyle}
										title="This image will be scaled down to 48x48px. It is recommended you upload in image no larger than 256x256px with even dimensions."
									/>
								</Space>
								<Break air />
								<MediaSelector
									style={{ width: 64, height: 64 }}
									{...ibnd('config/header/logo', '', state.storeID != '-1')}
									bordered
									dragAndDrop
									allowDelete
									maxDimensions={{ height: 256, width: 256 }}
								/>
							</div>
						)}

						{(headerType === 'c' || headerType === 'd') && (
							<div style={{ flex: 1 }}>
								<Space>
									<span style={{ color: '#8c8c8c' }}>Logo</span>
									<HoverQuestion
										style={hoverStyle}
										title="This image will be scaled down to have a max height of 36px"
									/>
								</Space>
								<Break air />
								<MediaSelector
									style={{ width: 64, height: 64 }}
									{...ibnd('config/header/logo', '', state.storeID != '-1')}
									bordered
									dragAndDrop
									allowDelete
								/>
							</div>
						)}
					</div>

					<Break
						air
						v={10}
					/>
					<Break />
					<Break
						air
						v={20}
					/>
				</>
			)}
		</>
	);
}
